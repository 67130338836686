var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "order-number",
      on: {
        click: function ($event) {
          return _vm.data.hendler(_vm.data.orderNumber)
        },
      },
    },
    [
      _c("span", { domProps: { textContent: _vm._s(_vm.data.orderNumber) } }),
      _c("span", {
        class: { red: _vm.red },
        domProps: { textContent: _vm._s(_vm.orderDoneDate) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }